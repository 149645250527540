import CardDropdown from "components/common/CardDropdown";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter";
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import { product_data } from "data/product/product-data";
import { useState } from "react";
import AddUser from "../users/add-user/AddUser";
import AddProductTab from "./AddProductTab";

const { default: BreadCrumb } = require("components/breadcrumb/BreadCrumb");
const { Card, Tabs, Tab, Row, Col, Dropdown } = require("react-bootstrap");

const columns = [
  {
    accessor: "name",
    Header: "Product Name",
    headerProps: { style: { minWidth: "200px" }, className: "ps-5 text-900" },
    cellProps: { className: "ps-5" },
  },
  {
    accessor: "category",
    Header: "Category",
    headerProps: { style: { minWidth: "200px" }, className: "ps-5 text-900" },
    cellProps: { className: "ps-5" },
  },
  {
    accessor: "available",
    Header: "Available",
    headerProps: { style: { minWidth: "200px" }, className: "ps-5 text-900" },
    cellProps: { className: "ps-5" },
  },

  {
    accessor: "none",
    Header: "",
    disableSortBy: true,
    cellProps: {
      className: "text-end",
    },
    Cell: () => {
      return (
        <CardDropdown>
          <div className="py-2">
            <Dropdown.Item href="#!">Edit</Dropdown.Item>
            <Dropdown.Item href="#!">Delete</Dropdown.Item>
          </div>
        </CardDropdown>
      );
    },
  },
];

export default function ProductsTabs() {
  const [key, setKey] = useState("home");
  const [products] = useState(product_data);

  return (
    <>
      <div className="py-2">
        <BreadCrumb />
      </div>

      <Card className="mb-3">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-9 mb-0 text-nowrap py-2 py-xl-0">
                Products Tabs
              </h5>
            </Col>
          </Row>
          {/* <UsersTableHeader table /> */}
        </Card.Header>
        <Card.Body className="p-0">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab
              eventKey="home"
              title="Products List"
              className="border-bottom border-x p-3 "
            >
              <AdvanceTableWrapper
                columns={columns}
                data={products}
                selection
                sortable
                pagination
                perPage={10}
              >
                <Row className="flex-end-center mb-3 px-3">
                  <Col xs="auto" sm={6} lg={4}>
                    <AdvanceTableSearchBox table />
                  </Col>
                </Row>
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    size: "sm",
                    striped: true,
                    className: "fs-10 mb-0 overflow-hidden",
                  }}
                />
                <Card.Footer>
                  <AdvanceTableFooter
                    rowCount={products.length}
                    table
                    rowInfo
                    navButtons
                    rowsPerPageSelection
                  />
                </Card.Footer>
                {/* <Card className="mb-3">
                  <Card.Header>
                    <ProductTableHeader table />
                  </Card.Header>
                  <Card.Body className="p-0">
                    <Row className="flex-end-center mb-3 px-3">
                      <Col xs="auto" sm={6} lg={4}>
                        <AdvanceTableSearchBox table />
                      </Col>
                    </Row>
                    <AdvanceTable
                      table
                      headerClassName="bg-200 text-nowrap align-middle"
                      rowClassName="align-middle white-space-nowrap"
                      tableProps={{
                        size: "sm",
                        striped: true,
                        className: "fs-10 mb-0 overflow-hidden",
                      }}
                    />
                  </Card.Body>
                  <Card.Footer>
                    <AdvanceTableFooter
                      rowCount={products.length}
                      table
                      rowInfo
                      navButtons
                      rowsPerPageSelection
                    />
                  </Card.Footer>
                </Card> */}
              </AdvanceTableWrapper>
            </Tab>
            <Tab
              eventKey="profile"
              title="Add Product"
              className="border-bottom border-x p-3"
            >
              <AddProductTab />
            </Tab>
          </Tabs>
        </Card.Body>
        {/* <Card.Footer>
          <AdvanceTableFooter
            rowCount={users.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
          />
        </Card.Footer> */}
      </Card>
    </>
  );
}
