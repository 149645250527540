export const usersData = [
  {
    name: "Ricky Antony",
    avatar: {
      name: "Ricky Antony",
    },
    email: "ricky@example.com",
    username: "RickyAntony1",
    role: "User",
    joined: "30/03/2018",
  },
  {
    name: "Ricky Antony",
    avatar: {
      name: "Ricky Antony",
    },
    email: "ricky@example.com",
    username: "RickyAntony1",
    role: "User",
    joined: "30/03/2018",
  },
  {
    name: "Ricky Antony",
    avatar: {
      name: "Ricky Antony",
    },
    email: "ricky@example.com",
    username: "RickyAntony1",
    role: "User",
    joined: "30/03/2018",
  },
  {
    name: "Ricky Antony",
    avatar: {
      name: "Ricky Antony",
    },
    email: "ricky@example.com",
    username: "RickyAntony1",
    role: "User",
    joined: "30/03/2018",
  },
];
