import React from "react";
import { Col, Row } from "react-bootstrap";
import Section from "components/common/Section";
import Logo from "components/common/Logo";
import Wizard from "components/wizard/Wizard";
import BreadCrumb from "components/breadcrumb/BreadCrumb";

const WizardAuth = () => {
  return (
    <Section className="py-0">
      <Row className="py-2">
        <BreadCrumb />
      </Row>
      <Row>
        <Col sm={10} lg={7} className="col-xxl">
          {/* <Logo width={45} textClass="fs-5" /> */}
          <Wizard validation={true} />
        </Col>
      </Row>
    </Section>
  );
};

export default WizardAuth;
