import CardDropdown from "components/common/CardDropdown";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "components/common/advance-table/AdvanceTablePagination";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import React, { useState } from "react";
import { Card, Col, Dropdown, Row } from "react-bootstrap";

import { rolesData } from "data/roles/rolesData";
import RolesAndPermissionsTableHeader from "./RolesAndPermissionTableHeader";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox";
import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter";
import BreadCrumb from "components/breadcrumb/BreadCrumb";

// const columns = [
//   {
//     accessor: "role",
//     Header: "Role",
//     headerProps: { className: "pe-1 text-900" },
//     cellProps: {
//       className: "py-2",
//     },
//     // Cell: (rowData) => {
//     //   const { name, avatar } = rowData.row.original;
//     //   return (
//     //     <Link to="#">
//     //       <Flex alignItems="center">
//     //         {avatar?.img ? (
//     //           <Avatar src={avatar?.img} size="xl" className="me-2" />
//     //         ) : (
//     //           <Avatar size="xl" name={avatar?.name} className="me-2" />
//     //         )}
//     //         <div className="flex-1">
//     //           <h5 className="mb-0 fs-10">{name}</h5>
//     //         </div>
//     //       </Flex>
//     //     </Link>
//     //   );
//     // },
//   },
//   {
//     accessor: "type",
//     Header: "Type",
//     headerProps: { className: "text-900" },
//     // Cell: (rowData) => {
//     //   const { email } = rowData.row.original;
//     //   return <a href={`mailto:${email}`}>{email}</a>;
//     // },
//   },

//   {
//     accessor: "none",
//     Header: "",
//     disableSortBy: true,
//     cellProps: {
//       className: "text-end",
//     },
//     Cell: () => {
//       return (
//         <CardDropdown>
//           <div className="py-2">
//             <Dropdown.Item>
//               <NavLink to={"/roles/assignment"}>Assignment</NavLink>
//             </Dropdown.Item>
//             <Dropdown.Item href="#!">Edit</Dropdown.Item>
//             <Dropdown.Item href="#!">Delete</Dropdown.Item>
//           </div>
//         </CardDropdown>
//       );
//     },
//   },
// ];

const RolesAndPermissions = () => {
  const navigate = useNavigate();

  const columns = [
    {
      accessor: "role",
      Header: "Role",
      headerProps: { className: "pe-1 text-900" },
      cellProps: {
        className: "py-2",
      },
      // Cell: (rowData) => {
      //   const { name, avatar } = rowData.row.original;
      //   return (
      //     <Link to="#">
      //       <Flex alignItems="center">
      //         {avatar?.img ? (
      //           <Avatar src={avatar?.img} size="xl" className="me-2" />
      //         ) : (
      //           <Avatar size="xl" name={avatar?.name} className="me-2" />
      //         )}
      //         <div className="flex-1">
      //           <h5 className="mb-0 fs-10">{name}</h5>
      //         </div>
      //       </Flex>
      //     </Link>
      //   );
      // },
    },
    {
      accessor: "type",
      Header: "Type",
      headerProps: { className: "text-900" },
      // Cell: (rowData) => {
      //   const { email } = rowData.row.original;
      //   return <a href={`mailto:${email}`}>{email}</a>;
      // },
    },

    {
      accessor: "none",
      Header: "",
      disableSortBy: true,
      cellProps: {
        className: "text-end",
      },
      Cell: () => {
        return (
          <CardDropdown>
            <div className="py-2">
              <Dropdown.Item onClick={() => navigate("/roles/assignment")}>
                Assignment
              </Dropdown.Item>
              <Dropdown.Item href="#!">Edit</Dropdown.Item>
              <Dropdown.Item href="#!">Delete</Dropdown.Item>
            </div>
          </CardDropdown>
        );
      },
    },
  ];

  const [roles] = useState(rolesData);
  return (
    <>
      <div className="py-2">
        <BreadCrumb />
      </div>

      <AdvanceTableWrapper
        columns={columns}
        data={roles}
        selection
        sortable
        pagination
        perPage={10}
      >
        <Card className="mb-3">
          <Card.Header>
            <RolesAndPermissionsTableHeader table />
          </Card.Header>
          <Card.Body className="p-0">
            <Row className="flex-end-center mb-3 px-3">
              <Col xs="auto" sm={6} lg={4}>
                <AdvanceTableSearchBox table />
              </Col>
            </Row>
            <AdvanceTable
              table
              headerClassName="bg-200 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: "sm",
                striped: true,
                className: "fs-10 mb-0 overflow-hidden",
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTableFooter
              rowCount={roles.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
    </>
  );
};

export default RolesAndPermissions;
