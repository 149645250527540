import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

export default function BreadCrumb() {
  const location = useLocation();
  const paths = location.pathname.split("/").filter((path) => path !== "");

  return (
    <div>
      <Link to="/">Home</Link>
      {paths.map((path, index) => (
        <span key={index}>
          {" / "}
          <Link to={`/${paths.slice(0, index + 1).join("/")}`}>
            {path.charAt(0).toUpperCase() + path.slice(1)}
          </Link>
        </span>
      ))}
    </div>
  );
}
