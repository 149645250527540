export const rolesData = [
  {
    role: "Admin",
    type: "System",
  },
  {
    role: "Admin",
    type: "System",
  },
  {
    role: "Admin",
    type: "System",
  },
  {
    role: "Admin",
    type: "System",
  },
  {
    role: "Admin",
    type: "System",
  },
  {
    role: "Admin",
    type: "System",
  },
  {
    role: "Admin",
    type: "System",
  },
];
