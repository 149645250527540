import Avatar from "components/common/Avatar";
import CardDropdown from "components/common/CardDropdown";
import Flex from "components/common/Flex";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "components/common/advance-table/AdvanceTablePagination";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import { usersData } from "data/users-data/users-data";
import React, { useState } from "react";
import { Card, Col, Dropdown, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import UsersTableHeader from "./UsersTableHeader";
import BreadCrumb from "components/breadcrumb/BreadCrumb";
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox";
import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter";

const columns = [
  {
    accessor: "name",
    Header: "Name",
    headerProps: { className: "pe-1 text-900" },
    cellProps: {
      className: "py-2",
    },
    Cell: (rowData) => {
      const { name, avatar } = rowData.row.original;
      return (
        <Link to="#">
          <Flex alignItems="center">
            {avatar?.img ? (
              <Avatar src={avatar?.img} size="xl" className="me-2" />
            ) : (
              <Avatar size="xl" name={avatar?.name} className="me-2" />
            )}
            <div className="flex-1">
              <h5 className="mb-0 fs-10">{name}</h5>
            </div>
          </Flex>
        </Link>
      );
    },
  },
  {
    accessor: "email",
    Header: "Email",
    headerProps: { className: "text-900" },
    Cell: (rowData) => {
      const { email } = rowData.row.original;
      return <a href={`mailto:${email}`}>{email}</a>;
    },
  },
  {
    accessor: "username",
    Header: "Username",
    headerProps: { className: "text-900" },

    // Cell: (rowData) => {
    //   const { phone } = rowData.row.original;
    //   return <a href={`tel:${phone}`}>{phone}</a>;
    // },
  },
  {
    accessor: "role",
    Header: "Role",
    headerProps: { className: "text-900" },
  },
  // {
  //   accessor: "address",
  //   Header: "Billing Address",
  //   headerProps: { style: { minWidth: "200px" }, className: "ps-5 text-900" },
  //   cellProps: { className: "ps-5" },
  // },
  {
    accessor: "joined",
    Header: "Joined",
    headerProps: { className: "text-900" },
  },
  {
    accessor: "none",
    Header: "",
    disableSortBy: true,
    cellProps: {
      className: "text-end",
    },
    Cell: () => {
      return (
        <CardDropdown>
          <div className="py-2">
            <Dropdown.Item href="#!">Edit</Dropdown.Item>
            <Dropdown.Item href="#!">Delete</Dropdown.Item>
          </div>
        </CardDropdown>
      );
    },
  },
];

const Users = () => {
  const [users] = useState(usersData);
  return (
    <>
      <div className="py-2">
        <BreadCrumb />
      </div>

      <AdvanceTableWrapper
        columns={columns}
        data={users}
        selection
        sortable
        pagination
        perPage={10}
      >
        <Card className="mb-3">
          <Card.Header>
            <UsersTableHeader table />
          </Card.Header>
          <Card.Body className="p-0">
            <Row className="flex-end-center mb-3 px-3">
              <Col xs="auto" sm={6} lg={4}>
                <AdvanceTableSearchBox table />
              </Col>
            </Row>
            <AdvanceTable
              table
              headerClassName="bg-200 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: "sm",
                striped: true,
                className: "fs-10 mb-0 overflow-hidden",
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTableFooter
              rowCount={users.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
    </>
  );
};

export default Users;
