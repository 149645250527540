import React, { useState } from "react";
import { Button, Col, Collapse, Form, Row } from "react-bootstrap";
import { FormProvider } from "react-hook-form";
import AssignmentHeader from "./AssignmentHeader";
import AssignmentFooter from "./AssignmentFooter";
import AssignmentBasicInformation from "./AssignmentBasicInformation";

export default function AddAssignment() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <FormProvider>
        <Form>
          <Row className="g-3">
            <Col xs={12}>
              <AssignmentHeader />
            </Col>
            <Col lg={12}>
              <AssignmentBasicInformation />
            </Col>
            <Col xs={12}>
              <AssignmentFooter />
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </>
  );
}
