import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import UserBasicInformation from "./UserBasicInformation";
import UserHeader from "./UserHeader";
import UserFooter from "./UserFooter";
import BreadCrumb from "components/breadcrumb/BreadCrumb";

const schema = yup
  .object({
    firstName: yup.string().required("This field is required."),
    lastName: yup.string().required("This field is required."),
    userName: yup.string().required("This field is required."),
    email: yup.string().required("This field is required."),
    password: yup.string().required("This field is required."),
    role: yup.string().required("This field is required."),
  })
  .required();

const AddUser = () => {
  const submittedValues = {};
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });
  const { handleSubmit, reset } = methods;

  const onSubmit = (data) => {
    console.log(data);
    // ------- Get all object keys form data and set empty values to reset ------------
    const keys = Object.keys(data);
    for (const key of keys) {
      submittedValues[key] = "";
    }
    reset({ ...submittedValues });
  };

  return (
    <>
      <div className="py-2">
        <BreadCrumb />
      </div>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="g-3">
            <Col xs={12}>
              <UserHeader />
            </Col>
            <Col lg={12}>
              <UserBasicInformation />
              {/* <ProductUpload /> */}
              {/* <ProductDetails /> */}
              {/* <ProductSpecifications /> */}
            </Col>
            <Col xs={12}>
              <UserFooter />
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </>
  );
};

export default AddUser;
