import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import Main from "./Main";
import "helpers/initFA";
import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css

const container = document.getElementById("main");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <PrimeReactProvider>
      <Main>
        <App />
      </Main>
    </PrimeReactProvider>
  </React.StrictMode>
);
