import React, { useState } from "react";
import { Button, Card, Col, Collapse, Form, Row, Table } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

const AssignmentBasicInformation = () => {
  const [studentInfo, setStudentInfo] = useState(false);
  const [feeCollection, setFeeCollection] = useState(false);
  //   const [studentInfo, setStudentInfo] = useState(false);

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-body-tertiary">
        {/* Basic information */}
        <div className="row ">
          <div className="col">Module Name</div>
          <div className="col">Feature</div>
          <div className="col d-flex gap-5">
            <div>View</div>
            <div>Add</div>
            <div>Edit</div>
            <div>Delete</div>
          </div>
        </div>
        {/* <Table responsive>
          <thead>
            <tr>
              <th scope="col" colSpan={3}>
                Module Name
              </th>
              <th scope="col">Feature</th>
              <th scope="col">View</th>
              <th scope="col">Add</th>
              <th scope="col">Edit</th>
              <th scope="col">Delete</th>
            </tr>
          </thead>
        </Table> */}
      </Card.Header>
      <Card.Body>
        <div>
          <Button
            onClick={() => setStudentInfo(!studentInfo)}
            aria-controls="example-collapse-text"
            aria-expanded={studentInfo}
            variant="falcon-default"
            className="mb-2  w-100"
          >
            Student Information
          </Button>
          <Collapse in={studentInfo}>
            <div className="border rounded">
              <div className="py-2">
                <div className="row ">
                  <h6 className="col"></h6>
                  <h6 className="col">Student</h6>
                  <h6 className="col d-flex gap-5">
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                  </h6>
                </div>
                <div className="row ">
                  <h6 className="col"></h6>
                  <h6 className="col">Import Student</h6>
                  <h6 className="col d-flex gap-5">
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                  </h6>
                </div>
                <div className="row ">
                  <h6 className="col"></h6>
                  <h6 className="col">Student Category</h6>
                  <h6 className="col d-flex gap-5">
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                  </h6>
                </div>
                <div className="row ">
                  <h6 className="col"></h6>
                  <h6 className="col">Student Houses</h6>
                  <h6 className="col d-flex gap-5">
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                  </h6>
                </div>
                <div className="row ">
                  <h6 className="col"></h6>
                  <h6 className="col">Disable Student</h6>
                  <h6 className="col d-flex gap-5">
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                  </h6>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
        <div>
          <Button
            onClick={() => setFeeCollection(!feeCollection)}
            aria-controls="example-collapse-text"
            aria-expanded={feeCollection}
            variant="falcon-default"
            className="my-2 w-100"
          >
            Fee Collection
          </Button>
          <Collapse in={feeCollection}>
            <div className="border rounded">
              <div className="py-2">
                <div className="row ">
                  <h6 className="col"></h6>
                  <h6 className="col">Collect Fee</h6>
                  <h6 className="col d-flex gap-5">
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                  </h6>
                </div>
                <div className="row ">
                  <h6 className="col"></h6>
                  <h6 className="col">Fees Carry Forward</h6>
                  <h6 className="col d-flex gap-5">
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                  </h6>
                </div>
                <div className="row ">
                  <h6 className="col"></h6>
                  <h6 className="col">Fees Master</h6>
                  <h6 className="col d-flex gap-5">
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                  </h6>
                </div>
                <div className="row ">
                  <h6 className="col"></h6>
                  <h6 className="col">Fees Group</h6>
                  <h6 className="col d-flex gap-5">
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                  </h6>
                </div>
                <div className="row ">
                  <h6 className="col"></h6>
                  <h6 className="col">Fees Group Assign</h6>
                  <h6 className="col d-flex gap-5">
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                  </h6>
                </div>
                <div className="row ">
                  <h6 className="col"></h6>
                  <h6 className="col">Fees Type</h6>
                  <h6 className="col d-flex gap-5">
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                    <Form.Check type="checkbox" id="defaultCheckbox" label="" />
                  </h6>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      </Card.Body>
    </Card>
  );
};

export default AssignmentBasicInformation;
