import React from "react";
import { Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

const ProductBasicInfo = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Row className="gx-2 gy-3">
      <Col md="12">
        <Form.Group>
          {/* <Form.Label>First name:</Form.Label> */}
          <FloatingLabel
            controlId="floatingInput"
            label="Product Name"
            className=""
          >
            <Form.Control
              type="text"
              placeholder=""
              isInvalid={!!errors.productName}
              {...register("productName")}
            />
            <Form.Control.Feedback type="invalid">
              {errors.productName?.message}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>
      </Col>
      <Col md="12">
        <Form.Group>
          <FloatingLabel
            controlId="floatingInput"
            label="Product Category"
            className=""
          >
            <Form.Control
              type="text"
              placeholder=""
              isInvalid={!!errors.productCategory}
              {...register("productCategory")}
            />
            <Form.Control.Feedback type="invalid">
              {errors.productCategory?.message}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>
      </Col>
      <Col md="12">
        <Form.Group>
          <FloatingLabel
            controlId="floatingInput"
            label="Product Description"
            className=""
          >
            <Form.Control
              type="text"
              placeholder=""
              isInvalid={!!errors.productDescription}
              {...register("productDescription")}
            />
            <Form.Control.Feedback type="invalid">
              {errors.productDescription?.message}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>
      </Col>
      <Col md="12">
        <Form.Group>
          <FloatingLabel controlId="floatingInput" label="Price" className="">
            <Form.Control
              type="text"
              placeholder="productPrice"
              isInvalid={!!errors.productPrice}
              {...register("productPrice")}
            />
            <Form.Control.Feedback type="invalid">
              {errors.productPrice?.message}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>
      </Col>

      {/* <Col md="12">
        <Form.Group>
          <FloatingLabel controlId="floatingSelectGrid" label="Role">
            <Form.Select
              aria-label="role"
              isInvalid={!!errors.role}
              {...register("role")}
            >
              <option value="">Select</option>
              <option value="User">User</option>
              <option value="Admin">Admin</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {errors.role?.message}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>
      </Col> */}
    </Row>
  );
};

export default ProductBasicInfo;
