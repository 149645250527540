import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import ProductBasicInfo from "./add-product-tab/ProductBasicInfo";

const schema = yup
  .object({
    productName: yup.string().required("This field is required."),
    productCategory: yup.string().required("This field is required."),
    productDescription: yup.string().required("This field is required."),
    productPrice: yup.string().required("This field is required."),
  })
  .required();

const AddUser = () => {
  const submittedValues = {};
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });
  const { handleSubmit, reset } = methods;

  const onSubmit = (data) => {
    console.log(data);
    // ------- Get all object keys form data and set empty values to reset ------------
    const keys = Object.keys(data);
    for (const key of keys) {
      submittedValues[key] = "";
    }
    reset({ ...submittedValues });
  };

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="g-3">
            {/* <Col xs={12}>
              <UserHeader />
            </Col> */}
            <Col lg={12}>
              <ProductBasicInfo />
              {/* <ProductUpload /> */}
              {/* <ProductDetails /> */}
              {/* <ProductSpecifications /> */}
            </Col>

            <Col xs={12}>
              <Row className="flex-between-center">
                <Col md>
                  <h5 className="mb-2 mb-md-0">You're almost done</h5>
                </Col>
                <Col xs="auto">
                  <Button
                    variant="link"
                    className="text-secondary fw-medium p-0 me-3"
                    type="button"
                  >
                    Discard
                  </Button>
                  <Button variant="primary" type="submit">
                    Add Product
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </>
  );
};

export default AddUser;
