import React from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

const RolesBasicInformation = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-body-tertiary">
        Basic information
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group>
              <Form.Label>Role</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.role}
                {...register("role")}
              />
              <Form.Control.Feedback type="invalid">
                {errors.role?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md="12">
            <Form.Group>
              <Form.Label>Type :</Form.Label>
              <Form.Select {...register(`type`)} isInvalid={!!errors.type}>
                <option value="">Select</option>
                <option value="System">System</option>
                {/* <option value="User">User</option> */}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.type?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default RolesBasicInformation;
