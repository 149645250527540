import Avatar from "components/common/Avatar";
import CardDropdown from "components/common/CardDropdown";
import Flex from "components/common/Flex";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "components/common/advance-table/AdvanceTablePagination";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import { product_data } from "data/product/product-data";
import React, { useEffect, useState } from "react";
import { Breadcrumb, Card, Col, Dropdown, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ProductTableHeader from "./ProductTableHeader";
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox";
import BreadCrumb from "components/breadcrumb/BreadCrumb";
import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter";
import { MdDeleteOutline, MdOutlineModeEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Product = () => {
  const navigate = useNavigate();

  const columns = [
    // {
    //   accessor: "name",
    //   Header: "Name",
    //   headerProps: { className: "pe-1 text-900" },
    //   cellProps: {
    //     className: "py-2",
    //   },
    //   Cell: (rowData) => {
    //     const { name, avatar } = rowData.row.original;
    //     return (
    //       <Link to="#">
    //         <Flex alignItems="center">
    //           {avatar.img ? (
    //             <Avatar src={avatar.img} size="xl" className="me-2" />
    //           ) : (
    //             <Avatar size="xl" name={avatar.name} className="me-2" />
    //           )}
    //           <div className="flex-1">
    //             <h5 className="mb-0 fs-10">{name}</h5>
    //           </div>
    //         </Flex>
    //       </Link>
    //     );
    //   },
    // },
    // {
    //   accessor: "email",
    //   Header: "Email",
    //   headerProps: { className: "text-900" },
    //   Cell: (rowData) => {
    //     const { email } = rowData.row.original;
    //     return <a href={`mailto:${email}`}>{email}</a>;
    //   },
    // },
    // {
    //   accessor: "phone",
    //   Header: "Phone",
    //   headerProps: { className: "text-900" },
    //   Cell: (rowData) => {
    //     const { phone } = rowData.row.original;
    //     return <a href={`tel:${phone}`}>{phone}</a>;
    //   },
    // },
    {
      accessor: "productName",
      Header: "Product Name",
      headerProps: { style: { minWidth: "200px" }, className: "ps-5 text-900" },
      cellProps: { className: "ps-5" },
    },
    {
      accessor: "productCategory",
      Header: "Category",
      headerProps: { style: { minWidth: "200px" }, className: "ps-5 text-900" },
      cellProps: { className: "ps-5" },
    },
    {
      accessor: "stock",
      Header: "Available",
      headerProps: { style: { minWidth: "200px" }, className: "ps-5 text-900" },
      cellProps: { className: "ps-5" },
    },

    {
      accessor: "none",
      Header: "",
      disableSortBy: true,
      cellProps: {
        className: "text-end",
      },
      Cell: ({ row }) => {
        return (
          <CardDropdown>
            <div className="py-2">
              <Dropdown.Item
                // as={Link}
                onClick={() => handleRowClick(row)}
                // href="/products/add-product"
                className="d-flex align-items-center gap-2"
              >
                <MdOutlineModeEdit />
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleDelete(row)}
                className="d-flex align-items-center gap-2"
              >
                <MdDeleteOutline />
                Delete
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      },
    },
  ];

  const [products, setProducts] = useState([]);

  const getData = async () => {
    let getDataFromLocalStorage = localStorage.getItem("data");

    if (getDataFromLocalStorage) {
      setProducts([JSON.parse(getDataFromLocalStorage)]);
    } else {
      setProducts([]);
    }
  };

  const handleRowClick = (row) => {
    navigate(`/products/add-product?id=${row.original.productName}`);
    console.log("Row data:", row.original.productName);
    // You can do whatever you need with the row data here
  };

  const handleDelete = (row) => {
    if (localStorage.getItem("data")) {
      localStorage.removeItem("data");
      toast.success("Delete Successfully");
      getData();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="py-2">
        <BreadCrumb />
      </div>

      <AdvanceTableWrapper
        columns={columns}
        data={products}
        selection
        sortable
        pagination
        perPage={10}
      >
        <Card className="mb-3">
          <Card.Header>
            <ProductTableHeader table />
          </Card.Header>
          <Card.Body className="p-0">
            <Row className="flex-end-center mb-3 px-3">
              <Col xs="auto" sm={6} lg={4}>
                <AdvanceTableSearchBox table />
              </Col>
            </Row>
            <AdvanceTable
              table
              getTableProps={(state, rowInfo, column) => {
                return {
                  onClick: () => handleRowClick(rowInfo),
                };
              }}
              headerClassName="bg-200 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: "sm",
                striped: true,
                className: "fs-10 mb-0 overflow-hidden",
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTableFooter
              rowCount={products.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
    </>
  );
};

export default Product;
