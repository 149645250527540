import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ProductBasicInformation from "./ProductBasicInformation";
import ProductHeader from "./ProductHeader";
import ProductUpload from "./ProductUpload";
import ProductFooter from "./ProductFooter";
import ProductDetails from "./ProductDetails";
import ProductType from "./ProductType";
import ProductTags from "./ProductTags";
import ProductPricing from "./ProductPricing";
import ProductShipping from "./ProductShipping";
import ProductStock from "./ProductStock";
import ProductSpecifications from "./ProductSpecifications";
import BreadCrumb from "components/breadcrumb/BreadCrumb";
import useQuery from "hooks/useQuery";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const schema = yup
  .object({
    productName: yup.string().required("This field is required."),
    manufacturarName: yup.string().required("This field is required."),
    identificationNumber: yup.string().required("This field is required."),
    productSummery: yup.string().required("This field is required."),
    importStatus: yup.string().required("This field is required."),
    countryOrigin: yup.string().required("This field is required."),
    productCategory: yup.string().required("This field is required."),
    productSubCategory: yup.string().required("This field is required."),
    regularPrice: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .required("This field is required."),
  })
  .required();

const AddProduct = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const formId = useQuery().get("id");

  const [data, setData] = useState({});

  const submittedValues = {};
  const methods = useForm({
    resolver: yupResolver(schema),

    // defaultValues:
    //  {
    //   specifications: [
    //     {
    //       label: "Processor",
    //       property: "2.3GHz quad-core Intel Core i5",
    //     },
    //     {
    //       label: "Memory",
    //       property: "8GB of 2133MHz LPDDR3 onboard memory",
    //     },
    //     {
    //       label: "Brand name",
    //       property: "Apple",
    //     },
    //   ],
    // },
  });
  const { handleSubmit, reset, setValue } = methods;

  // const onSubmit = (data) => {
  //   console.log(data);

  //   // ------- Get all object keys form data and set empty values to reset ------------
  //   const keys = Object.keys(data);
  //   for (const key of keys) {
  //     submittedValues[key] = "";
  //   }
  //   reset({ ...submittedValues });
  // };

  const onSubmit = (formData) => {
    // setData(formData);
    localStorage.setItem("data", JSON.stringify(formData));
    toast.success("Product Save Successfully!");
    reset(); // Reset the form after successful submission
  };

  useEffect(() => {
    if (formId) {
      let values = localStorage.getItem("data");
      let parseValue = JSON.parse(values);
      setValue("productName", parseValue.productName, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue("manufacturarName", parseValue.manufacturarName, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue("identificationNumber", parseValue.identificationNumber, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue("productSummery", parseValue.productSummery, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue("finalPrice", parseValue.finalPrice, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue("stock", parseValue.stock, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue("importStatus", parseValue.importStatus, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue("productCategory", parseValue.productCategory, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue("productSubCategory", parseValue.productSubCategory, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue("currency", parseValue.currency, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue("countryOrigin", parseValue.countryOrigin, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, []);

  const handleDelete = (index) => {
    setData(data.filter((_, i) => i !== index));
  };

  // function onSubmit(data) {
  //   return isAddMode ? createUser(data) : updateUser(id, data);
  // }

  // function createUser(data) {
  //   return userService
  //     .create(data)
  //     .then(() => {
  //       alertService.success("User added", { keepAfterRouteChange: true });
  //       history.push(".");
  //     })
  //     .catch(alertService.error);
  // }

  // function updateUser(id, data) {
  //   return userService
  //     .update(id, data)
  //     .then(() => {
  //       alertService.success("User updated", { keepAfterRouteChange: true });
  //       history.push("..");
  //     })
  //     .catch(alertService.error);
  // }

  // useEffect(() => {
  //   if (!isAddMode) {
  //     // get user and set form fields
  //     userService.getById(id).then((user) => {
  //       const fields = ["title", "firstName", "lastName", "email", "role"];
  //       fields.forEach((field) => setValue(field, user[field]));
  //     });
  //   }
  // }, []);

  return (
    <>
      <div className="py-2">
        <BreadCrumb />
      </div>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="g-3">
            <Col xs={12}>
              <ProductHeader />
            </Col>
            <Col lg={8}>
              <ProductBasicInformation />
              <ProductUpload />
              <ProductDetails />
              <ProductSpecifications />
            </Col>
            <Col lg={4}>
              <div className="sticky-sidebar">
                <ProductType />
                <ProductTags />
                <ProductPricing />
                <ProductShipping />
                <ProductStock />
              </div>
            </Col>
            <Col>
              <ProductFooter />
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </>
  );
};

export default AddProduct;
