import BreadCrumb from "components/breadcrumb/BreadCrumb";
import CardDropdown from "components/common/CardDropdown";
import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Dropdown,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import ScrollToTop from "react-scroll-to-top";
import axios from "axios";
import IconButton from "components/common/IconButton";
import InfiniteScroll from "react-infinite-scroll-component";
import { settings } from "config";

const InfiniteScrollPage = () => {
  const [formData, setFormData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const fetchMoreData = async () => {
    setLoading(true);
    setPage((prev) => prev + 1);
    const response = await axios.get(
      `https://jsonplaceholder.typicode.com/posts?_page=${page}&_limit=10`
    );
    setFormData([...formData, ...response.data]);
    setLoading(false);
  };

  useEffect(() => {
    fetchMoreData();
  }, []);

  return (
    <>
      <div className="py-2">
        <BreadCrumb />
      </div>

      <Card className="mb-3">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-9 mb-0 text-nowrap py-2 py-xl-0">
                Infinite Scroll
              </h5>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
              <OverlayTrigger
                overlay={
                  <Tooltip
                    style={{ position: "fixed" }}
                    id="overlay-trigger-example"
                  >
                    New
                  </Tooltip>
                }
              >
                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="plus"
                  transform="shrink-3"
                  //   onClick={() => navigate("#")}
                >
                  <span className="d-none d-sm-inline-block ms-1">New</span>
                </IconButton>
              </OverlayTrigger>
            </Col>
          </Row>
        </Card.Header>

        <InfiniteScroll
          dataLength={formData.length}
          next={fetchMoreData}
          hasMore={formData.length !== 90}
          loader={
            <div className="d-flex justify-content-center overflow-hidden mb-3">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          }
        >
          <Table striped responsive>
            <thead className="bg-200 text-nowrap align-middle fs-10 mb-0 overflow-hidden">
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Title</th>
                <th scope="col">Body</th>
                <th className="text-end" scope="col">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {formData.map((data, index) => (
                <tr key={index}>
                  <td>{data.id}</td>
                  <td>{data.title}</td>
                  <td>{data.body}</td>

                  <td className="text-end">
                    <CardDropdown>
                      <div className="py-2">
                        <Dropdown.Item>Edit</Dropdown.Item>
                        <Dropdown.Item className="text-danger">
                          Delete
                        </Dropdown.Item>
                      </div>
                    </CardDropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </InfiniteScroll>
      </Card>

      {/* <AdvanceTableWrapper
        columns={columns}
        data={users}
        selection
        sortable
        pagination
        perPage={10}
      >
        <Card className="mb-3">
          <Card.Header>
            <UsersTableHeader table />
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: "sm",
                striped: true,
                className: "fs-10 mb-0 overflow-hidden",
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTablePagination table />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper> */}
      <ScrollToTop smooth />
    </>
  );
};

export default InfiniteScrollPage;
