import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react"; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { Button, Dropdown } from "react-bootstrap";
import {
  clearLocalStorage,
  getLocalStorage,
  setLocalStorage,
} from "./utils/StoreState";

export default function DataGridSecond() {
  const columnApiRef = useRef();
  // let gridApi;
  const [selectedItems, setSelectedItems] = useState([]);
  const [columnCustom, setColumnCustom] = useState([]);
  // const [gridApi, setGridApi] = useState();
  // const [columnApi, setColumnApi] = useState();

  const [rowData, setRowData] = useState([
    // { make: "Tesla", model: "Model Y", price: 64950, electric: true },
    // { make: "Ford", model: "F-Series", price: 33850, electric: false },
    // { make: "Toyota", model: "Corolla", price: 29600, electric: false },
  ]);

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs, setColDefs] = useState([
    // { field: "", filter: false, headerName: "Select" },

    {
      field: "athlete",
      headerName: "Athlete",
      // checkboxSelection: true,
      // headerCheckboxSelection: true,
      // rowDrag: true,
      hide: false,
    },
    { field: "country", headerName: "Country", hide: false },
    { field: "sport", headerName: "Sports", hide: false },
    { field: "date", headerName: "Date", hide: false },
    // {
    //   headerName: "Action",
    //   field: "price",
    //   filter: false,
    //   cellRenderer: (p) => (
    //     <div>
    //       <button onClick={() => console.log(p)}>Click</button>
    //     </div>
    //   ),
    // },
  ]);

  const defaultColDef = {
    sortable: true,
    filter: true,
    floatingFilter: true,
    flex: 1,
    // rowDrag: true,
  };

  //  ========== Export as CSV Start =============

  // const forExportData = (e) => {
  //   gridApi = e.api;
  // };
  // const onExportClick = () => {
  //   gridApi.exportDataAsCsv();
  // };
  //  ========== Export as CSV End =============

  //   ===============Custom Api Call start================

  let gridApi;
  let columnApi;

  const onGridReady = useCallback(
    (e) => {
      gridApi = e.api;
      columnApi = e.columnApi;
      // console.log(params);
      // setGridApi(params.api);
      // setColumnApi(e.columnApi);
      // gridApi = e.api;

      // setGridState(gridApi);
      restoreState();

      console.log("ready");
      fetch("https://www.ag-grid.com/example-assets/olympic-winners.json")
        .then((resp) => resp.json())
        .then((res) => {
          // console.log(res)
          e.api.applyTransaction({ add: res });
          // e.api.paginationGoToPage(10);
        });
      //   .then((data) => {
      //     setRowData(data);
      //   });
    },
    [window]
  );

  //   ===============Custom Api Call End================
  const onRowSelected = (e) => {
    // console.log(e.api.getSelectedRows());
  };

  const paginationPageSizeSelector = [10, 20, 50, 100, 200, 500, 1000];

  const saveState = useCallback(() => {
    const colState = columnApiRef.current.api.getColumnState();
    setLocalStorage(colState);
    console.log("column state saved");
  }, []);

  const restoreState = useCallback(() => {
    const colState = getLocalStorage();
    if (!colState) {
      console.log("no columns state to restore by, you must save state first");
      return;
    }
    columnApiRef.current.api.applyColumnState({
      state: colState,
      applyOrder: true,
    });
    console.log("column state restored");
  }, []);

  const resetState = useCallback(() => {
    columnApiRef.current.api.resetColumnState();
    clearLocalStorage();
    console.log("column state reset");
  }, []);

  const showColumn = () => {
    columnApiRef.current.api.setColumnsVisible([...selectedItems], false);

    // setHideColumn(!hideColumn);
    // gridApi.sizeColumnsToFit();
    // setHideColumn((hideColumn) => !hideColumn);
    // gridApi.setColumnsVisible("country", true);
  };

  // useEffect(() => {
  //   let columState;
  //   if (localStorage.getItem("grid")) {
  //     columState = localStorage.getItem("grid");
  //     setColumnCustom(columState);
  //   }
  // }, []);

  // console.log(columnCustom);

  const handleShowColumn = (e, col) => {
    console.log(col.field);

    const { value, checked } = e.target;

    if (checked) {
      // Add the value to the array if checkbox is checked
      setSelectedItems([...selectedItems, col.field]);
    } else {
      // Remove the value from the array if checkbox is unchecked
      setSelectedItems(selectedItems.filter((item) => item !== col.field));
    }

    // if (!selectedItems.includes(col.field)) {
    //   // If not selected, add it to the array
    //   setSelectedItems([...selectedItems, col.field]);
    // }

    // let hiddenItems = [""];
    // hiddenItems.push(col.field);

    // setHideColumn(hiddenItems);

    // setColDefs((state) =>
    //   state.map((_state) =>
    //     _state.field === col.field ? { ..._state, hide: !col.hide } : _state
    //   )
    // );
  };

  // useEffect(() => {
  //   const stateFromLocalStorage = getLocalStorage();
  //   setHideColumn(stateFromLocalStorage);
  // }, []);

  // console.log(hideColumn);

  return (
    <div
      className="ag-theme-quartz" // applying the grid theme
      style={{ height: 700 }} // the grid will fill the size of the parent container
    >
      <div className="test-header">
        <div className="example-section">
          <button onClick={saveState}>Save State</button>
          <button onClick={restoreState}>Restore State</button>
          <button onClick={resetState}>Reset State</button>

          {/* <button onClick={showColumn}>Show / Hide Column</button> */}

          {/* {colDefs.map((col) => (
            <div key={col.field} className="flex items-center  gap-3">
              <input
                // id={col}
                type="checkbox"
                // checked={localStorage.getItem("grid")}
                onClick={
                  (e) => handleShowColumn(e, col)
                  // (e) =>
                  // setColDefs((state) =>
                  //   state.map((_state) =>
                  //     _state.field === col.field
                  //       ? { ..._state, hide: !col.hide }
                  //       : _state
                  //   )
                  // )
                }
              />
              <label htmlFor="enabled">{col.headerName}</label>
            </div>
          ))} */}
        </div>
      </div>

      {/* <button onClick={() => onExportClick()}>abc</button> */}
      <AgGridReact
        // rowData={rowData}
        ref={columnApiRef}
        onGridReady={onGridReady}
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        // onGridReady={forExportData}
        // tooltipShowDelay={2000}
        // tooltipHideDelay={2000}
        // rowSelection="multiple"
        // onRowSelected={onRowSelected}
        // rowMultiSelectWithClick={true}
        pagination={true}
        // paginationPageSizeSelector={paginationPageSizeSelector}
        // paginationPageSize={50}
        // rowDragEntireRow={true}
        // rowDragManaged={true}
        // sideBar={true}
        // paginationAutoPageSize={true}
      />
    </div>
  );
}
