import person2 from "assets/img/team/2.jpg";
import person3 from "assets/img/team/3.jpg";
import person4 from "assets/img/team/4.jpg";
import person5 from "assets/img/team/5.jpg";
import person6 from "assets/img/team/6.jpg";
import person9 from "assets/img/team/9.jpg";
import person13 from "assets/img/team/13.jpg";
import person14 from "assets/img/team/14.jpg";

export const product_data = [
  {
    name: "Apple AirPods 13",
    avatar: {
      name: "Ricky Antony",
    },
    category: "Electronics",
    available: "Yes",
  },
  {
    name: "Cooler",
    avatar: {
      name: "Ricky Antony",
    },
    category: "Electronics",
    available: "Yes",
  },
  {
    name: "Fan",
    avatar: {
      name: "Ricky Antony",
    },
    category: "Electronics",
    available: "Yes",
  },
  {
    name: "Fridge",
    avatar: {
      name: "Ricky Antony",
    },
    category: "Electronics",
    available: "Yes",
  },
  {
    name: "Refrigerator",
    avatar: {
      name: "Ricky Antony",
    },
    category: "Electronics",
    available: "Yes",
  },
  {
    name: "Apple AirPods 13",
    avatar: {
      name: "Ricky Antony",
    },
    category: "Electronics",
    available: "Yes",
  },
  {
    name: "Cooler",
    avatar: {
      name: "Ricky Antony",
    },
    category: "Electronics",
    available: "Yes",
  },
  {
    name: "Fan",
    avatar: {
      name: "Ricky Antony",
    },
    category: "Electronics",
    available: "Yes",
  },
  {
    name: "Fridge",
    avatar: {
      name: "Ricky Antony",
    },
    category: "Electronics",
    available: "Yes",
  },
  {
    name: "Refrigerator",
    avatar: {
      name: "Ricky Antony",
    },
    category: "Electronics",
    available: "Yes",
  },
  {
    name: "Apple AirPods 13",
    avatar: {
      name: "Ricky Antony",
    },
    category: "Electronics",
    available: "Yes",
  },
  {
    name: "Cooler",
    avatar: {
      name: "Ricky Antony",
    },
    category: "Electronics",
    available: "Yes",
  },
  {
    name: "Fan",
    avatar: {
      name: "Ricky Antony",
    },
    category: "Electronics",
    available: "Yes",
  },
  {
    name: "Fridge",
    avatar: {
      name: "Ricky Antony",
    },
    category: "Electronics",
    available: "Yes",
  },
  {
    name: "Refrigerator",
    avatar: {
      name: "Ricky Antony",
    },
    category: "Electronics",
    available: "Yes",
  },
];
